<script setup>
import {ref} from "vue";
import {login} from "@/plugins/users";

const username = ref("");
const password = ref("");

const submitForm = () => {
  login(username.value, password.value)
}


</script>

<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card>
          <v-card-title class="text-h5">Вход в личный кабинет</v-card-title>
          <v-card-text>
            <v-form>
              <v-text-field v-model="username" label="Логин"></v-text-field>
              <v-text-field v-model="password" placeholder="Пароль" type="password"></v-text-field>
              <v-btn color="primary" @click="submitForm()">
                Войти
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<style scoped>
input {
  width: 440px !important;
  height: 40px;
  padding: 16px;
  display: flex;
  border: 1px solid #AFB5BE;
  border-radius: 4px;
  outline: none;

  &:focus {
    border: 1px solid #004164;
  }

  &:disabled {
    text-overflow: ellipsis;

    &:hover {
      transition: all 200ms ease-in;
      border: 1px solid #AFB5BE;
    }
  }
}

</style>