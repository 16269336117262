import axios from "axios";
import {logout} from "@/plugins/users";

// axios.defaults.baseURL = window.location.host === 'localhost:8080' ? 'http://0.0.0.0:8889/api' : 'http://167.235.132.53:8889/api';
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;

console.log(axios.defaults.baseURL, "<-- api-url")

axios.defaults.headers['Cache-Control'] = 'no-store';
axios.defaults.headers['Access-Control-Allow-Origin'] = "*";

axios.interceptors.request.use((request) => {
  const token = localStorage.getItem("access");
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
});

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 401) {
    logout()
  }
  return Promise.reject(error);
});
export default axios;