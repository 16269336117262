import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";
import { createPinia } from 'pinia';

import '@vuepic/vue-datepicker/dist/main.css';
import VueDatePicker from '@vuepic/vue-datepicker';

// vuetify
import 'vuetify/styles';
import vuetify from "@/plugins/vuetify";

const app = createApp(App);


app.use(vuetify);
app.use(router);
app.use(createPinia());

app.component("DatePicker", VueDatePicker);

app.mount('#app')