import axios from "@/plugins/axios";
import router from "@/router";
import toast from "@/plugins/bulmatoast";

export function login(username, password) {
    axios.defaults.headers.common["Authorization"] = "";
    localStorage.removeItem("access");
    localStorage.removeItem("role");
    localStorage.removeItem("username");

    axios.post("/auth/login", {username: username, password: password})
        .then(response => {
            axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.access_token;
            localStorage.setItem("access", response.data.access_token);
            localStorage.setItem("role", response.data.access_role);
            localStorage.setItem("username", response.data.username);
            router.push('/')
            router.go(0)
        }).catch(() => {
        toast({
            message: 'Предоставлены не верные данные для входа',
            type: "is-danger",
        });
    })
}

export function logout() {
    localStorage.removeItem("access");
    localStorage.removeItem("role");
    localStorage.removeItem("username");
    router.push('/')
    router.go(0)
}