import 'vuetify/styles';

import { createVuetify } from "vuetify";
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@mdi/font/css/materialdesignicons.css';


const vuetify = createVuetify({
    components, directives,
    theme: {
      themes: {
        light: {
          dark: false,
          colors: {
            primary: "#005B8C",
            main: "#004164",
            red: "#9E0F0F",
            green: "#85A72F",
            blue: "#005B8C",
          }
        },
      },
    },
  });

  export default vuetify;